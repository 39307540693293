import React from "react";
import AnnuityCalculator from "./components/AnnuityCalculator";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import "./App.css";

function App() {
  return <AnnuityCalculator />;
}

export default App;
