export const stringToDate = (_date) => {
  // console.log(_date);
  if (_date !== "") {
    var dateItems = _date.split("/");
    var formatedDate = new Date(dateItems[2], dateItems[0] - 1, dateItems[1]);
    return formatedDate;
  } else {
    new Date();
  }
};
