import React, { useState } from "react";
import { Form, Container, Alert, Col, Row, InputGroup } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsInfoCircle } from "react-icons/bs";
import CurrencyInput from "react-currency-input-field";

const WithdrawalsForm = ({ annuiyInputData, handleAnnuityInputData }) => {
  const [sysWDLocal, setSysWDLocal] = useState(0);
  const handleChangeWithdrawlType = (event) => {
    handleAnnuityInputData(event.target.name, event.target.value);
    if (
      event.target.value === "Interest Only" ||
      event.target.value === "Max Free Withdrawal"
    ) {
      handleAnnuityInputData("SysWDMode", "Annual");
    } else {
      handleAnnuityInputData("SysWDMode", "");
    }
    // handleAnnuityInputData("SysWDMode", "");
    setSysWDLocal(0);
    handleAnnuityInputData("SysWD", 0);
    handleAnnuityInputData("SysWDYear", 0);
    handleAnnuityInputData("SysWDYearEnd", 0);
  };
  const handleSetSysWDLocal = (value) => {
    setSysWDLocal(value);
    if (annuiyInputData["SysWDType"] === "Specify %") {
      const valueLoc = parseFloat(value) / 100;
      handleAnnuityInputData("SysWD", valueLoc.toFixed(2));
    } else {
      handleAnnuityInputData("SysWD", parseFloat(value));
    }
  };
  return (
    <Container>
      <Form>
        <Alert key="primaryWithdrawalsForm" variant="primary">
          Withdrawals
        </Alert>

        <Form.Group
          as={Row}
          className="mb-3"
          controlId="WithdrawalType"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            Withdrawal Type{" "}
            <OverlayTrigger
              key={"right2"}
              placement={"right"}
              overlay={<Tooltip>This field is required!</Tooltip>}
            >
              <sup
                style={{
                  color: annuiyInputData["SysWDType"] !== "" ? "green" : "red",
                  marginLeft: "5px",
                  fontSize: "13px",
                }}
              >
                <BsInfoCircle />
              </sup>
            </OverlayTrigger>
          </Form.Label>
          <Col sm={9}>
            <Form.Select
              name="SysWDType"
              value={
                annuiyInputData["SysWDType"] ? annuiyInputData["SysWDType"] : ""
              }
              onChange={(e) => handleChangeWithdrawlType(e)}
            >
              <option value="">Choose...</option>
              <option value="None">None</option>
              <option value="Interest Only">Interest Only</option>
              <option value="Specify Amount">Specify Amount</option>
              <option value="Specify %">Specify %</option>
              {annuiyInputData["Liquidity"] === true ? (
                <option value="Max Free Withdrawal">Max Free Withdrawal</option>
              ) : null}
            </Form.Select>
          </Col>
        </Form.Group>
        {annuiyInputData["SysWDType"] &&
        annuiyInputData["SysWDType"] !== "None" ? (
          <>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="WithdrawalMode"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                Withdrawal Mode{" "}
                <OverlayTrigger
                  key={"right2"}
                  placement={"right"}
                  overlay={<Tooltip>This field is required!</Tooltip>}
                >
                  <sup
                    style={{
                      color:
                        annuiyInputData["SysWDMode"] !== "" ? "green" : "red",
                      marginLeft: "5px",
                      fontSize: "13px",
                    }}
                  >
                    <BsInfoCircle />
                  </sup>
                </OverlayTrigger>
              </Form.Label>
              <Col sm={9}>
                <Form.Select
                  name="SysWDMode"
                  disabled={
                    annuiyInputData["SysWDType"] &&
                    annuiyInputData["SysWDType"] === "Max Free Withdrawal"
                      ? "disabled"
                      : false
                  }
                  value={
                    annuiyInputData["SysWDMode"]
                      ? annuiyInputData["SysWDMode"]
                      : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                >
                  <option value="">Choose...</option>
                  <option value="Annual">Annual</option>
                  <option value="Semiannual">Semiannual</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Monthly">Monthly</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="WithdrawalAmount"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                Withdrawal{" "}
                {annuiyInputData["SysWDType"] &&
                annuiyInputData["SysWDType"] === "Specify %"
                  ? "Percentage"
                  : "Amount"}{" "}
                {annuiyInputData["SysWDType"] &&
                (annuiyInputData["SysWDType"] === "Interest Only" ||
                  annuiyInputData["SysWDType"] === "Max Free Withdrawal") ? (
                  ""
                ) : (
                  <OverlayTrigger
                    key={"right2"}
                    placement={"right"}
                    overlay={<Tooltip>This field is required!</Tooltip>}
                  >
                    <sup
                      style={{
                        color:
                          annuiyInputData["SysWDType"] === "Specify Amount" &&
                          annuiyInputData["SysWD"] >= 50
                            ? "green"
                            : annuiyInputData["SysWDType"] !==
                                "Specify Amount" && annuiyInputData["SysWD"] > 0
                            ? "green"
                            : "red",
                        marginLeft: "5px",
                        fontSize: "13px",
                      }}
                    >
                      <BsInfoCircle />
                    </sup>
                  </OverlayTrigger>
                )}
              </Form.Label>
              <Col sm={9}>
                {annuiyInputData["SysWDType"] &&
                annuiyInputData["SysWDType"] !== "Specify Amount" ? (
                  <InputGroup className="mb-3">
                    <Form.Control
                      disabled={
                        annuiyInputData["SysWDType"] &&
                        (annuiyInputData["SysWDType"] === "Interest Only" ||
                          annuiyInputData["SysWDType"] ===
                            "Max Free Withdrawal")
                          ? "disabled"
                          : false
                      }
                      type="text"
                      name="SysWD"
                      value={sysWDLocal ? sysWDLocal : 0}
                      onChange={(e) => handleSetSysWDLocal(e.target.value)}
                    />
                    <InputGroup.Text>
                      {annuiyInputData["SysWDType"] &&
                      annuiyInputData["SysWDType"] === "Specify %"
                        ? "%"
                        : "USD"}
                    </InputGroup.Text>
                  </InputGroup>
                ) : (
                  <>
                    <CurrencyInput
                      style={{
                        width: "100%",
                        border: "1px solid #ced4da",
                        borderRadius: "5px",
                        lineHeight: "2",
                      }}
                      maxLength="2000000"
                      prefix="$ "
                      id="SysWD"
                      name="SysWD"
                      placeholder="Please enter a number"
                      value={
                        annuiyInputData["SysWD"] ? annuiyInputData["SysWD"] : ""
                      }
                      decimalsLimit={2}
                      onValueChange={(value, name) =>
                        handleAnnuityInputData(name, value)
                      }
                    />
                    {parseInt(annuiyInputData["SysWD"]) < 50 ? (
                      <Form.Text id="LiquidityHelpBlock">
                        Min for Withdrawal $50.
                      </Form.Text>
                    ) : null}
                  </>
                )}
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="InterestRate"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                Start in Policy Year{" "}
                <OverlayTrigger
                  key={"right2"}
                  placement={"right"}
                  overlay={<Tooltip>This field is required!</Tooltip>}
                >
                  <sup
                    style={{
                      color: annuiyInputData["SysWDYear"] > 0 ? "green" : "red",
                      marginLeft: "5px",
                      fontSize: "13px",
                    }}
                  >
                    <BsInfoCircle />
                  </sup>
                </OverlayTrigger>
              </Form.Label>
              <Col sm={9}>
                {/* <DatePicker
                  selected={
                    annuiyInputData["SysWDYear"] !== null
                      ? stringToDate(
                          annuiyInputData["SysWDYear"],
                          "dd/MM/yyyy",
                          "/"
                        )
                      : null
                  }
                  onChange={(date) => handletartDate(date)}
                  dropdownMode="select"
                  showYearDropdown
                  dateFormat="MM/dd/yyyy"
                /> */}
                <Form.Control
                min="0" 
                  type="number"
                  placeholder="0"
                  name="SysWDYear"
                  value={
                    annuiyInputData["SysWDYear"]
                      ? annuiyInputData["SysWDYear"]
                      : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                />
                {/* <Form.Control
                  type="date"
                  name="SysWDYear"
                  value={
                    annuiyInputData["SysWDYear"]
                      ? annuiyInputData["SysWDYear"]
                      : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                  placeholder="Start in Year"
                /> */}
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="SysWDYearno"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                Number of Years{" "}
                <OverlayTrigger
                  key={"right2"}
                  placement={"right"}
                  overlay={<Tooltip>This field is required!</Tooltip>}
                >
                  <sup
                    style={{
                      color:
                        annuiyInputData["SysWDYearEnd"] > 0 ? "green" : "red",
                      marginLeft: "5px",
                      fontSize: "13px",
                    }}
                  >
                    <BsInfoCircle />
                  </sup>
                </OverlayTrigger>
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                   min="0" 
                  type="number"
                  placeholder="0"
                  name="SysWDYearEnd"
                  value={
                    annuiyInputData["SysWDYearEnd"]
                      ? annuiyInputData["SysWDYearEnd"]
                      : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                />
              </Col>
            </Form.Group>
          </>
        ) : null}
      </Form>
    </Container>
  );
};

export default WithdrawalsForm;
