import React from "react";
import { Form, Container, Alert, Col, Row, InputGroup } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsInfoCircle } from "react-icons/bs";
import CurrencyInput from "react-currency-input-field";

const ContractForm = ({ annuiyInputData, handleAnnuityInputData }) => {
  const handleLiquidity = (name, value) => {
    if (
      JSON.parse(value) === false &&
      annuiyInputData["SysWDType"] &&
      annuiyInputData["SysWDType"] === "Max Free Withdrawal"
    ) {
      handleAnnuityInputData("SysWDType", "None");
      handleAnnuityInputData("SysWDMode", "");
      handleAnnuityInputData("SysWD", 0);
      handleAnnuityInputData("SysWDYear", "");
      handleAnnuityInputData("SysWDYearEnd", "");
    }
    handleAnnuityInputData(name, JSON.parse(value));
  };
  return (
    <Container>
      <Alert key="primaryContractForm" variant="primary">
        Contract Specifications
      </Alert>

      <Form>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="formDeposit"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            Deposit{" "}
            <OverlayTrigger
              key={"right2"}
              placement={"right"}
              overlay={<Tooltip>This field is required!</Tooltip>}
            >
              <sup
                style={{
                  color:
                    annuiyInputData["initamt"] !== "" &&
                    parseInt(annuiyInputData["initamt"]) >= 50000 &&
                    parseInt(annuiyInputData["initamt"]) <= 2000000
                      ? "green"
                      : "red",
                  marginLeft: "5px",
                  fontSize: "13px",
                }}
              >
                <BsInfoCircle />
              </sup>
            </OverlayTrigger>
          </Form.Label>
          <Col sm={9}>
            <InputGroup className="mb-3">
              <CurrencyInput
                style={{
                  width: "100%",
                  border: "1px solid #ced4da",
                  borderRadius: "5px",
                  lineHeight: "2",
                }}
                maxLength="2000000"
                prefix="$ "
                id="initamt"
                name="initamt"
                placeholder="Please enter a number"
                value={
                  annuiyInputData["initamt"] ? annuiyInputData["initamt"] : ""
                }
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  handleAnnuityInputData(name, value)
                }
              />
              {parseInt(annuiyInputData["initamt"]) < 50000 ||
              parseInt(annuiyInputData["initamt"]) > 2000000 ? (
                <Form.Text id="LiquidityHelpBlock">
                  Min/Max for deposit ($50K-2M).
                </Form.Text>
              ) : null}

              {/* <Form.Control
                type="text"
                placeholder="100,000.00"
                name="initamt"
                value={
                  annuiyInputData["initamt"] ? annuiyInputData["initamt"] : ""
                }
                onChange={(e) =>
                  handleAnnuityInputData(e.target.name, e.target.value)
                }
              /> */}
              {/* <InputGroup.Text>USD</InputGroup.Text> */}
            </InputGroup>
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="OwnerTaxQualification"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            Tax Qualification{" "}
            <OverlayTrigger
              key={"right2"}
              placement={"right"}
              overlay={<Tooltip>This field is required!</Tooltip>}
            >
              <sup
                style={{
                  color:
                    annuiyInputData["TaxQualification"] !== ""
                      ? "green"
                      : "red",
                  marginLeft: "5px",
                  fontSize: "13px",
                }}
              >
                <BsInfoCircle />
              </sup>
            </OverlayTrigger>
          </Form.Label>
          <Col sm={9}>
            <Form.Select
              name="TaxQualification"
              value={
                annuiyInputData["TaxQualification"]
                  ? annuiyInputData["TaxQualification"]
                  : ""
              }
              onChange={(e) =>
                handleAnnuityInputData(e.target.name, e.target.value)
              }
            >
              <option value="">Choose...</option>
              <option value="Qualified">Qualified</option>
              <option value="Non-Qualified">Non-Qualified</option>
            </Form.Select>
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="GuaranteedPeriod"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            Guaranteed Period{" "}
            <OverlayTrigger
              key={"right2"}
              placement={"right"}
              overlay={<Tooltip>This field is required!</Tooltip>}
            >
              <sup
                style={{
                  color: annuiyInputData["Duration"] !== "" ? "green" : "red",
                  marginLeft: "5px",
                  fontSize: "13px",
                }}
              >
                <BsInfoCircle />
              </sup>
            </OverlayTrigger>
          </Form.Label>
          <Col sm={9}>
            <Form.Select
              name="Duration"
              value={
                annuiyInputData["Duration"] ? annuiyInputData["Duration"] : ""
              }
              onChange={(e) =>
                handleAnnuityInputData(e.target.name, e.target.value)
              }
            >
              <option value="">Choose...</option>
              <option value="3">3 Years</option>
              <option value="5">5 Years</option>
              <option value="7">7 Years</option>
            </Form.Select>
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="LiquidityOption"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            Liquidity Option{" "}
            <OverlayTrigger
              key={"right2"}
              placement={"right"}
              overlay={<Tooltip>This field is required!</Tooltip>}
            >
              <sup
                style={{
                  color: "green",
                  marginLeft: "5px",
                  fontSize: "13px",
                }}
              >
                <BsInfoCircle />
              </sup>
            </OverlayTrigger>
          </Form.Label>
          <Col sm={9}>
            <Form.Select
              name="Liquidity"
              value={annuiyInputData["Liquidity"]}
              onChange={(e) => handleLiquidity(e.target.name, e.target.value)}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Form.Select>
            {/* {annuiyInputData["Liquidity"] === true ? ( */}
            <Form.Text id="LiquidityHelpBlock" muted>
              Electing Liquidity Option allows for 10% Free Withdrawals at the
              cost of a 0.15% Interest Rate Reduction.
            </Form.Text>
            {/* ) : null} */}
          </Col>
        </Form.Group>
        {/* <Form.Group
          as={Row}
          className="mb-3"
          controlId="InterestRate"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            Interest Rate
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              placeholder="Interest Rate"
              name="InterestRate"
              disabled
              value={
                annuiyInputData["InterestRate"]
                  ? annuiyInputData["InterestRate"]
                  : ""
              }
              onChange={(e) =>
                handleAnnuityInputData(e.target.name, e.target.value)
              }
            />
          </Col>
        </Form.Group> */}
      </Form>
    </Container>
  );
};

export default ContractForm;
