import React from "react";
import { useState, useEffect } from "react"; //useEffect,
import { Container, Row, Col, Button, ListGroup } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsInfoCircle } from "react-icons/bs";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Spinner from "react-bootstrap/Spinner";
import HeaderBar from "./HeaderBar";
import JurisdictionForm from "../forms/JurisdictionForm";
import ContractForm from "../forms/ContractForm";
import WithdrawalsForm from "../forms/WithdrawalsForm";
import PrimaryOwnerForm from "../forms/PrimaryOwnerForm";
import PrimaryAnnuitantForm from "../forms/PrimaryAnnuitantForm";
import ProducerInformationForm from "../forms/ProducerInformationForm";
import IllustrationOutput from "./IllustrationOutput";
import * as Constants from "../Constants";
import FooterBar from "./FooterBar";

const AnnuityCalculator = () => {
  const [responseObject, setResponseObject] = useState({});
  const [formStep, setFormStep] = useState(1);
  const [isValidateJurisdiction, setIsValidateJurisdiction] = useState(false);
  const [isValidateContract, setIsValidateContract] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loadingTrue, setLoadingTrue] = useState(false);
  const [ageType, setAgeType] = useState("age");
  const [ageTypeJoint, setAgeTypeJoint] = useState("age");
  const [annuiyInputData, setAnnuiyInputData] = useState({
    Product: "Rowan, A Multi-Year Guaranteed Annuity",
    InterestRate: "",
    DateofBirthOwner: null,
    JointDateofBirth: null,

    Ann2Age: 0,
    Ann2FirstName: "",
    Ann2Gender: "",
    Ann2LastName: "",
    AnnAge: 0,
    AnnFirstName: "",
    AnnGender: "",
    AnnLastName: "",
    AnnType: "",
    Duration: "7",
    initamt: 0,
    Liquidity: false,
    Own2Age: 0,
    Own2FirstName: "",
    Own2Gender: "",
    Own2LastName: "",
    OwnAge: 0,
    OwnerType: "",
    OwnFirstName: "",
    OwnGender: "",
    OwnLastName: "",
    ProdAdd1: "",
    ProdAdd2: "",
    ProdEmail: "",
    ProdFirstName: "",
    ProdLastName: "",
    ProdPhone: "",
    State: "NC",
    SysWD: 0,
    SysWDMode: "",
    SysWDType: "None",
    SysWDYear: 0,
    SysWDYearEnd: 0,
    TaxQualification: "",
  });
  const handleAnnuityInputData = (fieldName, fieldValue) => {
    setIsValidate(false);
    // console.log(fieldValue);
    setAnnuiyInputData((prevStat) => ({
      ...prevStat,
      [fieldName]: fieldValue,
    }));
  };
  const handlePrintReords = () => {
    setLoadingTrue(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-tenant-name", "merit-life");
    myHeaders.append("x-synthetic-key", "29414a9c-a852-499c-8ce5-e1540afa9647");
    var raw = JSON.stringify({
      request_data: {
        inputs: annuiyInputData,
      },
      request_meta: {
        // version_id: Constants.VERSION_ID,
        compiler_type: Constants.API_TYPE_PRINT,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "manual",
    };

    fetch(Constants.API_URL, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setLoadingTrue(false);
        const responseOutput = JSON.parse(result);
        if (
          responseOutput.status &&
          responseOutput.status === "Success" &&
          responseOutput.response_data &&
          responseOutput.response_data.outputs &&
          responseOutput.response_data.outputs.ILLUSTRATION &&
          responseOutput.response_data.outputs.ILLUSTRATION.PDFUrl
        ) {
          const link = document.createElement("a");
          link.href = responseOutput.response_data.outputs.ILLUSTRATION.PDFUrl;
          link.setAttribute(
            "download",
            responseOutput.response_data.outputs.ILLUSTRATION.PDFUrl
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        // eslint-disable-next-line
      })
      .catch((error) => console.log("error", error));
  };
  const handleSubmitReords = () => {
    if (isValidate) {
      setLoadingTrue(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-tenant-name", "merit-life");
      myHeaders.append(
        "x-synthetic-key",
        "29414a9c-a852-499c-8ce5-e1540afa9647"
      );
      var raw = JSON.stringify({
        request_data: {
          inputs: annuiyInputData,
        },
        request_meta: {
          // version_id: Constants.VERSION_ID,
          compiler_type: Constants.API_TYPE_CAL,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "manual",
      };

      fetch(Constants.API_URL, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setLoadingTrue(false);
          const responseOutput = JSON.parse(result);
          if (
            responseOutput.status &&
            responseOutput.status === "Success" &&
            responseOutput.response_data &&
            responseOutput.response_data.outputs &&
            responseOutput.response_data.outputs.Illustration &&
            responseOutput.response_data.outputs.Illustration.length > 0
          ) {
            setFormStep(5);
            setResponseObject(responseOutput.response_data.outputs);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      setShowToast(true);
    }
  };
  // console.log(annuiyInputData);
  useEffect(() => {
    if (annuiyInputData["State"] !== "" && annuiyInputData["Product"] !== "") {
      setIsValidateJurisdiction(true);
    } else {
      setIsValidateJurisdiction(false);
    }
    if (
      annuiyInputData["State"] !== "" &&
      annuiyInputData["Product"] !== "" &&
      annuiyInputData["initamt"] !== "" &&
      parseInt(annuiyInputData["initamt"]) >= 50000 &&
      parseInt(annuiyInputData["initamt"]) <= 2000000 &&
      annuiyInputData["TaxQualification"] !== "" &&
      annuiyInputData["Duration"] !== "" &&
      annuiyInputData["SysWDType"] !== ""
    ) {
      if (annuiyInputData["SysWDType"] !== "None") {
        if (
          annuiyInputData["SysWDType"] !== "" &&
          annuiyInputData["SysWDYear"] > 0 &&
          annuiyInputData["SysWDYearEnd"] > 0
        ) {
          if (
            annuiyInputData["SysWDType"] === "Specify Amount" &&
            annuiyInputData["SysWD"] > 49
          ) {
            setIsValidate(true);
            setIsValidateContract(true);
          } else if (
            annuiyInputData["SysWDType"] === "Specify %" &&
            annuiyInputData["SysWD"] > 0
          ) {
            setIsValidate(true);
            setIsValidateContract(true);
          } else if (
            annuiyInputData["SysWDType"] !== "Specify %" &&
            annuiyInputData["SysWDType"] !== "Specify Amount"
          ) {
            setIsValidate(true);
            setIsValidateContract(true);
          } else {
            setIsValidateContract(false);
          }
        } else {
          setIsValidateContract(false);
        }
      } else {
        setIsValidate(true);
        setIsValidateContract(true);
      }
    }
  }, [annuiyInputData]);

  return (
    <section>
      <HeaderBar />

      <Container className="pb-10">
        {/* <Alert key="warning" variant="warning" style={{ marginTop: "30px" }}>
				Let's Answer a few Questions...
			</Alert> */}

        <Row
          className="justify-content-md-center"
          style={{ marginBottom: "40px" }}
        >
          <Col lg="3" className="d-none d-sm-block">
            <ListGroup numbered>
              {/* className="completed" */}
              <ListGroup.Item
                action
                active={formStep === 1 ? "active" : undefined}
                onClick={() => setFormStep(1)}
              >
                Product/Jurisdiction{" "}
                {isValidateJurisdiction ? null : (
                  <OverlayTrigger
                    key={"right2"}
                    placement={"right"}
                    overlay={<Tooltip>This page has required fields!</Tooltip>}
                  >
                    <sup
                      style={{
                        color: "red",
                        marginLeft: "5px",
                        fontSize: "13px",
                      }}
                    >
                      <BsInfoCircle />
                    </sup>
                  </OverlayTrigger>
                )}
              </ListGroup.Item>
              {/* disabled */}
              <ListGroup.Item
                action
                className=""
                active={formStep === 2 ? "active" : undefined}
                onClick={() => setFormStep(2)}
              >
                Client Info
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => setFormStep(3)}
                active={formStep === 3 ? "active" : undefined}
              >
                Contract Specs{" "}
                {isValidateContract ? null : (
                  <OverlayTrigger
                    key={"right2"}
                    placement={"right"}
                    overlay={<Tooltip>This page has required fields!</Tooltip>}
                  >
                    <sup
                      style={{
                        color: "red",
                        marginLeft: "5px",
                        fontSize: "13px",
                      }}
                    >
                      <BsInfoCircle />
                    </sup>
                  </OverlayTrigger>
                  // <span style={{ color: "red" }}>*</span>
                )}
              </ListGroup.Item>
              <ListGroup.Item
                action
                active={formStep === 4 ? "active" : undefined}
                onClick={() => setFormStep(4)}
              >
                Producer Info
              </ListGroup.Item>
              <ListGroup.Item
                action
                className={isValidate ? "" : "disabledIt"}
                active={formStep === 5 ? "active" : undefined}
                onClick={handleSubmitReords}
              >
                {loadingTrue ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Loading...
                  </>
                ) : (
                  "Quick View Results"
                )}
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col xs lg="9">
            {formStep === 1 ? (
              <JurisdictionForm
                annuiyInputData={annuiyInputData}
                handleAnnuityInputData={handleAnnuityInputData}
              />
            ) : formStep === 2 ? (
              <>
                <PrimaryOwnerForm
                  ageType={ageType}
                  ageTypeJoint={ageTypeJoint}
                  setAgeType={setAgeType}
                  setAgeTypeJoint={setAgeTypeJoint}
                  annuiyInputData={annuiyInputData}
                  handleAnnuityInputData={handleAnnuityInputData}
                />
                <PrimaryAnnuitantForm
                  annuiyInputData={annuiyInputData}
                  handleAnnuityInputData={handleAnnuityInputData}
                />
              </>
            ) : formStep === 3 ? (
              <>
                <ContractForm
                  annuiyInputData={annuiyInputData}
                  handleAnnuityInputData={handleAnnuityInputData}
                />
                <br />
                <br />
                <WithdrawalsForm
                  annuiyInputData={annuiyInputData}
                  handleAnnuityInputData={handleAnnuityInputData}
                />
              </>
            ) : formStep === 4 ? (
              <ProducerInformationForm
                annuiyInputData={annuiyInputData}
                handleAnnuityInputData={handleAnnuityInputData}
              />
            ) : formStep === 5 ? (
              <IllustrationOutput
                annuiyInputData={annuiyInputData}
                responseObject={responseObject}
              />
            ) : null}

            {formStep > 1 && formStep <= 5 ? (
              <Button
                variant="secondary"
                onClick={() => setFormStep(formStep - 1)}
              >
                {"<<"} Back
              </Button>
            ) : null}
            {formStep < 4 ? (
              <Button
                variant="primary"
                onClick={() => setFormStep(formStep + 1)}
              >
                Next {">>"}
              </Button>
            ) : null}
            {formStep === 4 ? (
              <Button
                variant="primary"
                onClick={handleSubmitReords}
                // disabled={isValidate ? false : "disabled"}
              >
                {loadingTrue ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </>
                ) : (
                  "Run Illustration"
                )}
              </Button>
            ) : null}
            {formStep === 5 ? (
              <Button variant="primary" onClick={handlePrintReords}>
                {loadingTrue ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </>
                ) : (
                  "Print Illustration"
                )}
              </Button>
            ) : null}
          </Col>
        </Row>
        <Row>
          <ToastContainer className="p-3" position={"middle-center"}>
            <Toast
              className="d-inline-block m-1"
              bg="light"
              key={"light-id"}
              show={showToast}
              animation={true}
              onClose={() => setShowToast(false)}
            >
              <Toast.Header>
                <strong className="me-auto">Missing Information</strong>
                {/* <small>11 mins ago</small> */}
              </Toast.Header>
              <Toast.Body>Please fill up all the required fields!</Toast.Body>
            </Toast>
          </ToastContainer>
        </Row>
      </Container>

      <FooterBar />
    </section>
  );
};

export default AnnuityCalculator;
