import React from "react";
import { Form, Container, Alert, Row, Col } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsInfoCircle } from "react-icons/bs";
const JurisdictionForm = ({ annuiyInputData, handleAnnuityInputData }) => {
  return (
    <Container>
      <Alert key="primaryJurisdictionForm" variant="primary">
        Select a Jurisdiction and Product
      </Alert>
      <Form>
        <Form.Group as={Row}
          className="mb-3" controlId="formJurisdiction" style={{ marginTop: "10px" }}>
          <Form.Label column sm={3}>
            Jurisdiction{" "}
            <OverlayTrigger
              key={"right1"}
              placement={"right"}
              overlay={<Tooltip>This field is required!</Tooltip>}
            >
              <sup
                style={{
                  color: annuiyInputData["State"] !== "" ? "green" : "red",
                  marginLeft: "5px",
                  fontSize: "13px",
                }}
              >
                <BsInfoCircle />
              </sup>
            </OverlayTrigger>
          </Form.Label>
          <Col sm={9}>
          <Form.Control
            as="select"
            name="State"
            //   defaultValue={this.props.inputValues.state}
            value={annuiyInputData["State"] ? annuiyInputData["State"] : ""}
            onChange={(e) =>
              handleAnnuityInputData(e.target.name, e.target.value)
            }
          >
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}
          className="mb-3" controlId="formProduct" style={{ marginTop: "10px" }}>
          <Form.Label column sm={3}>
            Product{" "}
            <OverlayTrigger
              key={"right2"}
              placement={"right"}
              overlay={<Tooltip>This field is required!</Tooltip>}
            >
              <sup
                style={{
                  color: annuiyInputData["Product"] !== "" ? "green" : "red",
                  marginLeft: "5px",
                  fontSize: "13px",
                }}
              >
                <BsInfoCircle />
              </sup>
            </OverlayTrigger>
          </Form.Label>
          <Col sm={9}>
          <Form.Select
            name="Product"
            value={annuiyInputData["Product"] ? annuiyInputData["Product"] : ""}
            onChange={(e) =>
              handleAnnuityInputData(e.target.name, e.target.value)
            }
          >
            <option value="">Choose...</option>
            <option value="Rowan, A Multi-Year Guaranteed Annuity">Rowan, A Multi-Year Guaranteed Annuity</option>
          </Form.Select>
          </Col>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default JurisdictionForm;
