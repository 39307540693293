import React from "react";
import DatePicker from "react-datepicker";
import { Form, Container, Alert, Col, Row } from "react-bootstrap";
import { stringToDate } from "../components/DatePickerCustom";

const PrimaryOwnerForm = ({
  annuiyInputData,
  handleAnnuityInputData,
  setAgeTypeJoint,
  setAgeType,
  ageTypeJoint,
  ageType,
}) => {
  const getAge = (birthDate) =>
    Math.floor((new Date() - birthDate.getTime()) / 3.15576e10);

  const handleChangeOwnerType = (event) => {
    handleAnnuityInputData(event.target.name, event.target.value);
    if (
      event.target.value !== "Individual" &&
      event.target.value !== "Joint" &&
      event.target.value !== ""
    ) {
      handleAnnuityInputData("OwnFirstName", "Valued " + event.target.value);
    } else {
      handleAnnuityInputData("OwnFirstName", "");
    }

    handleAnnuityInputData("OwnLastName", "");
    handleAnnuityInputData("DateofBirthOwner", "");
    handleAnnuityInputData("OwnAge", 0);
    handleAnnuityInputData("OwnGender", "");
    handleAnnuityInputData("Own2FirstName", "");
    handleAnnuityInputData("Own2LastName", "");
    handleAnnuityInputData("JointDateofBirth", "");
    handleAnnuityInputData("Own2Age", 0);
    handleAnnuityInputData("Own2Gender", "");
  };
  const handletartDateOwner = (updatedDate) => {
    handleAnnuityInputData(
      "DateofBirthOwner",
      updatedDate.toLocaleDateString("en-US")
    );
    handleAnnuityInputData("OwnAge", getAge(updatedDate));
    // console.log(updatedDate.toLocaleDateString("en-US"));
  };
  const handletartDateJoint = (updatedDate) => {
    handleAnnuityInputData(
      "JointDateofBirth",
      updatedDate.toLocaleDateString("en-US")
    );
    handleAnnuityInputData("Own2Age", getAge(updatedDate));
    // console.log(updatedDate.toLocaleDateString("en-US"));
  };
  return (
    <Container>
      <Alert key="primaryPrimaryOwnerForm" variant="primary">
        Primary Owner Information
      </Alert>

      <Form>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="OwnerType"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            Owner Type
          </Form.Label>
          <Col sm={9}>
            <Form.Select
              name="OwnerType"
              value={
                annuiyInputData["OwnerType"] ? annuiyInputData["OwnerType"] : ""
              }
              onChange={(e) => handleChangeOwnerType(e)}
            >
              <option value={""}>Choose...</option>
              <option value="Individual">Individual</option>
              <option value="Joint">Joint</option>
              <option value="Trust">Trust</option>
              <option value="Custodian">Custodian</option>
              <option value="Corporation">Corporation</option>
            </Form.Select>
          </Col>
        </Form.Group>
        {/* Owner Information Section */}
        <Alert key="primaryOwner" variant="secondary">
          Owner Information
        </Alert>

        <Form.Group
          as={Row}
          className="mb-3"
          controlId="NameFirst"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            {annuiyInputData["OwnerType"] &&
            (annuiyInputData["OwnerType"] === "Individual" ||
              annuiyInputData["OwnerType"] === "Joint")
              ? "First Name"
              : "Name"}
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              placeholder="Name"
              name="OwnFirstName"
              value={
                annuiyInputData["OwnFirstName"]
                  ? annuiyInputData["OwnFirstName"]
                  : ""
              }
              onChange={(e) =>
                handleAnnuityInputData(e.target.name, e.target.value)
              }
            />
          </Col>
        </Form.Group>
        {annuiyInputData["OwnerType"] &&
        (annuiyInputData["OwnerType"] === "Individual" ||
          annuiyInputData["OwnerType"] === "Joint") ? (
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="LastName"
            style={{ marginTop: "10px" }}
          >
            <Form.Label column sm={3}>
              Last Name
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                placeholder="Name"
                name="OwnLastName"
                value={
                  annuiyInputData["OwnLastName"]
                    ? annuiyInputData["OwnLastName"]
                    : ""
                }
                onChange={(e) =>
                  handleAnnuityInputData(e.target.name, e.target.value)
                }
              />
            </Col>
          </Form.Group>
        ) : null}
        {annuiyInputData["OwnerType"] &&
        (annuiyInputData["OwnerType"] === "Individual" ||
          annuiyInputData["OwnerType"] === "Joint") ? (
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="DateofBirthOwner"
            style={{ marginTop: "10px" }}
          >
            <Form.Label column sm={3}>
              Enter Age / Date of Birth
            </Form.Label>
            <Col sm={9}>
              <Form.Check
                inline
                checked={ageType === "dob" ? "checked" : false}
                label="Date of Birth"
                name="ageType"
                type="radio"
                id={`inline-radio-2`}
                onChange={(e) => setAgeType("dob")}
              />
              <Form.Check
                checked={ageType === "age" ? "checked" : false}
                inline
                label="Age"
                name="ageType"
                type="radio"
                id={`inline-radio-1`}
                onChange={(e) => setAgeType("age")}
              />
            </Col>
          </Form.Group>
        ) : null}
        {annuiyInputData["OwnerType"] &&
        (annuiyInputData["OwnerType"] === "Individual" ||
          annuiyInputData["OwnerType"] === "Joint") &&
        ageType === "dob" ? (
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="DateofBirthOwner"
            style={{ marginTop: "10px" }}
          >
            <Form.Label column sm={3}>
              Date of Birth
            </Form.Label>
            <Col sm={9}>
              <DatePicker
                selected={
                  annuiyInputData["DateofBirthOwner"] !== null
                    ? stringToDate(annuiyInputData["DateofBirthOwner"])
                    : null
                }
                onChange={(date) => handletartDateOwner(date)}
                dropdownMode="select"
                showYearDropdown
                dateFormat="MM/dd/yyyy"
              />
              {/* <Form.Control
              type="date"
              name="DateofBirthOwner"
              value={
                annuiyInputData["DateofBirthOwner"]
                  ? annuiyInputData["DateofBirthOwner"]
                  : ""
              }
              onChange={(e) => handleBOD(e)}
              placeholder="Date of Birth"
            /> */}
            </Col>
          </Form.Group>
        ) : null}
        {annuiyInputData["OwnerType"] &&
        (annuiyInputData["OwnerType"] === "Individual" ||
          annuiyInputData["OwnerType"] === "Joint") ? (
          <>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="Age"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                Age
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  disabled={ageType === "age" ? false : "disabled"}
                  type="text"
                  placeholder="Age"
                  name="OwnAge"
                  value={
                    annuiyInputData["OwnAge"] ? annuiyInputData["OwnAge"] : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="Gender"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                Gender
              </Form.Label>
              <Col sm={9}>
                <Form.Select
                  name="OwnGender"
                  value={
                    annuiyInputData["OwnGender"]
                      ? annuiyInputData["OwnGender"]
                      : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                >
                  <option value={""}>Choose...</option>
                  <option value={"Male"}>Male</option>
                  <option value={"Female"}>Female</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </>
        ) : null}
        {/* Joint Owner Information Section */}
        {annuiyInputData["OwnerType"] &&
        annuiyInputData["OwnerType"] === "Joint" ? (
          <>
            <Alert key="secondaryJoint" variant="secondary">
              Joint Owner Information
            </Alert>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="JointNameFirst"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                First Name
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="Own2FirstName"
                  value={
                    annuiyInputData["Own2FirstName"]
                      ? annuiyInputData["Own2FirstName"]
                      : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="JointLastName"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                Last Name
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="Own2LastName"
                  value={
                    annuiyInputData["Own2LastName"]
                      ? annuiyInputData["Own2LastName"]
                      : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="DateofBirthOwner"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                Enter Age / Date of Birth
              </Form.Label>
              <Col sm={9}>
                <Form.Check
                  inline
                  checked={ageTypeJoint === "dob" ? "checked" : false}
                  label="Date of Birth"
                  name="ageTypeJoint"
                  type="radio"
                  id={`inline-radio-2`}
                  onChange={(e) => setAgeTypeJoint("dob")}
                />
                <Form.Check
                  inline
                  checked={ageTypeJoint === "age" ? "checked" : false}
                  label="Age"
                  name="ageTypeJoint"
                  type="radio"
                  id={`inline-radio-1`}
                  onChange={(e) => setAgeTypeJoint("age")}
                />
              </Col>
            </Form.Group>
            {ageTypeJoint === "dob" ? (
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="JointDateofBirth"
                style={{ marginTop: "10px" }}
              >
                <Form.Label column sm={3}>
                  Date of Birth
                </Form.Label>
                <Col sm={9}>
                  <DatePicker
                    selected={
                      annuiyInputData["JointDateofBirth"] !== null
                        ? stringToDate(
                            annuiyInputData["JointDateofBirth"],
                            "dd/MM/yyyy",
                            "/"
                          )
                        : null
                    }
                    onChange={(date) => handletartDateJoint(date)}
                    dropdownMode="select"
                    showYearDropdown
                    dateFormat="MM/dd/yyyy"
                  />
                  {/* <Form.Control
                  type="date"
                  name="JointDateofBirth"
                  value={
                    annuiyInputData["JointDateofBirth"]
                      ? annuiyInputData["JointDateofBirth"]
                      : ""
                  }
                  onChange={(e) => handleBOD(e)}
                  placeholder="Date of Birth"
                /> */}
                </Col>
              </Form.Group>
            ) : null}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="JointAge"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                Age
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  disabled={ageTypeJoint === "age" ? false : "disabled"}
                  type="text"
                  placeholder="Age"
                  name="Own2Age"
                  value={
                    annuiyInputData["Own2Age"] ? annuiyInputData["Own2Age"] : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="JointGender"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                Gender
              </Form.Label>
              <Col sm={9}>
                <Form.Select
                  name="Own2Gender"
                  value={
                    annuiyInputData["Own2Gender"]
                      ? annuiyInputData["Own2Gender"]
                      : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                >
                  <option value={""}>Choose...</option>
                  <option value={"Male"}>Male</option>
                  <option value={"Female"}>Female</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </>
        ) : null}
      </Form>
    </Container>
  );
};

export default PrimaryOwnerForm;
