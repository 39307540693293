import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assests/innovator-logo.png";

function HeaderBar() {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Collapse href="#home" className="justify-content-start">
          <img
            alt=""
            src={logo}
            width="200"
            className="d-inline-block align-top"
          />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default HeaderBar;
