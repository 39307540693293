import React from "react";
import { Form, Container, Alert, Row, Col } from "react-bootstrap";

const PrimaryAnnuitantForm = ({ annuiyInputData, handleAnnuityInputData }) => {
  const handleChangeOwnerType = (event) => {
    handleAnnuityInputData(event.target.name, event.target.value);

    handleAnnuityInputData("AnnFirstName", "");
    handleAnnuityInputData("AnnLastName", "");
    handleAnnuityInputData("AnnAge", 0);
    handleAnnuityInputData("AnnGender", "");
    handleAnnuityInputData("Ann2FirstName", "");
    handleAnnuityInputData("Ann2LastName", "");
    handleAnnuityInputData("Ann2Age", 0);
    handleAnnuityInputData("Ann2Gender", "");
  };
  return (
    <Container>
      <Alert key="primaryPrimaryAnnuitantForm" variant="primary">
        Primary Annuitant Information
      </Alert>
      <Form>
        <Form.Group as={Row} className="mb-3" style={{ marginTop: "10px" }}>
          <Form.Label column sm={3}>
            Annuitant Type
          </Form.Label>
          <Col sm={9}>
            <Form.Select
              name="AnnType"
              value={
                annuiyInputData["AnnType"] ? annuiyInputData["AnnType"] : ""
              }
              onChange={(e) => handleChangeOwnerType(e)}
            >
              <option value={""}>Choose...</option>
              <option value="Same as Owner">Same as Owner</option>
              <option value="Individual">Individual</option>
              <option value="Joint">Joint</option>
            </Form.Select>
          </Col>
        </Form.Group>
        {annuiyInputData["AnnType"] &&
        annuiyInputData["AnnType"] !== "Same as Owner" ? (
          <>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="AnnuitantNameFirst"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                First Name
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  name="AnnFirstName"
                  value={
                    annuiyInputData["AnnFirstName"]
                      ? annuiyInputData["AnnFirstName"]
                      : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="AnnuitantLastName"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                Last Name
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  name="AnnLastName"
                  value={
                    annuiyInputData["AnnLastName"]
                      ? annuiyInputData["AnnLastName"]
                      : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="AnnuitantAge"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                Age
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Age"
                  name="AnnAge"
                  value={
                    annuiyInputData["AnnAge"] ? annuiyInputData["AnnAge"] : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="AnnuitantGender"
              style={{ marginTop: "10px" }}
            >
              <Form.Label column sm={3}>
                Gender
              </Form.Label>
              <Col sm={9}>
                <Form.Select
                  name="AnnGender"
                  value={
                    annuiyInputData["AnnGender"]
                      ? annuiyInputData["AnnGender"]
                      : ""
                  }
                  onChange={(e) =>
                    handleAnnuityInputData(e.target.name, e.target.value)
                  }
                >
                  <option value={""}>Choose...</option>
                  <option value={"Male"}>Male</option>
                  <option value={"Female"}>Female</option>
                </Form.Select>
              </Col>
            </Form.Group>
            {/* Joint */}
            {annuiyInputData["AnnType"] &&
            annuiyInputData["AnnType"] === "Joint" ? (
              <>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="JointAnnuitantNameFirst"
                  style={{ marginTop: "10px" }}
                >
                  <Form.Label column sm={3}>
                    First Name
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      name="Ann2FirstName"
                      value={
                        annuiyInputData["Ann2FirstName"]
                          ? annuiyInputData["Ann2FirstName"]
                          : ""
                      }
                      onChange={(e) =>
                        handleAnnuityInputData(e.target.name, e.target.value)
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="JointAnnuitantLastName"
                  style={{ marginTop: "10px" }}
                >
                  <Form.Label column sm={3}>
                    Last Name
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      name="Ann2LastName"
                      value={
                        annuiyInputData["Ann2LastName"]
                          ? annuiyInputData["Ann2LastName"]
                          : ""
                      }
                      onChange={(e) =>
                        handleAnnuityInputData(e.target.name, e.target.value)
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="JointAnnuitantAge"
                  style={{ marginTop: "10px" }}
                >
                  <Form.Label column sm={3}>
                    Age
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Age"
                      name="Ann2Age"
                      value={
                        annuiyInputData["Ann2Age"]
                          ? annuiyInputData["Ann2Age"]
                          : ""
                      }
                      onChange={(e) =>
                        handleAnnuityInputData(e.target.name, e.target.value)
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="JointAnnuitantGender"
                  style={{ marginTop: "10px" }}
                >
                  <Form.Label column sm={3}>
                    Gender
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Select
                      name="Ann2Gender"
                      value={
                        annuiyInputData["Ann2Gender"]
                          ? annuiyInputData["Ann2Gender"]
                          : ""
                      }
                      onChange={(e) =>
                        handleAnnuityInputData(e.target.name, e.target.value)
                      }
                    >
                      <option value={""}>Choose...</option>
                      <option value={"Male"}>Male</option>
                      <option value={"Female"}>Female</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </>
            ) : null}
          </>
        ) : null}
      </Form>
    </Container>
  );
};

export default PrimaryAnnuitantForm;
