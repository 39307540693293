import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "",
    },
  },
  animation: {
    duration: 1,
    onComplete: function () {
      // console.log(this.controller);
      // var chartInstance = this.Chart;
      var ctx = this.ctx;
      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";
      // this.data.datasets.forEach(function (dataset, i) {
      //   var meta = this.controller.getDatasetMeta(i);
      //   meta.data.forEach(function (bar, index) {
      //     if (dataset.data[index] > 0) {
      //       var data = dataset.data[index];
      //       ctx.fillText(data, bar._model.x, bar._model.y);
      //     }
      //   });
      // });
    },
  },
};

const labels = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  // "21",
  // "22",
  // "23",
  // "24",
  // "25",
  // "26",
  // "27",
  // "28",
  // "29",
  // "30",
];

export const data = {
  labels,
  datasets: [],
};

const IllustrationOutput = ({ annuiyInputData, responseObject }) => {
  const [datasetsGraph, setDatasetsGraph] = useState([]);
  // const [graphLabels, setGraphLabels] = useState([]);
  const [datagroups, setDatagroups] = useState([]);

  useEffect(() => {
    if (responseObject.Illustration.length > 0) {
      const datagroupsLocal = [];
      const datasetGuaranteed = [];
      const datasetProjected = [];
      const datasetGuaranteedWithdrawals = [];
      const datasetProjectedWithdrawals = [];
      responseObject.Illustration.forEach((element) => {
        datasetGuaranteed.push(element["Guaranteed Surrender Value"]);
        datasetProjected.push(element["Projected Surrender Value"]);
        if (annuiyInputData["SysWDType"] !== "None") {
          datasetGuaranteedWithdrawals.push(
            element["Guaranteed Annual Withdrawal"]
          );
          datasetProjectedWithdrawals.push(
            element["Projected Annual Withdrawal"]
          );
        }

        let localdg = {
          GuaranteedSurrender: element["Guaranteed Surrender Value"],
          ProjectedSurrender: element["Projected Surrender Value"],
          GuaranteedWithdrawal: element["Guaranteed Annual Withdrawal"],
          ProjectedWithdrawal: element["Projected Annual Withdrawal"],
        };
        datagroupsLocal.push(localdg);
      });
      console.log(datagroupsLocal)
      const datasetGraph = [
        {
          label: "Guaranteed Surrender Value",
          data: datasetGuaranteed,
          backgroundColor: "rgba(186, 205, 221)",
        },
        {
          label: "Projected Surrender Value",
          data: datasetProjected,
          backgroundColor: "rgba(32,50,66)",
        },
      ];
      // const graphLabelsLocal = [];
      // for (var i = 1; i <= parseInt(annuiyInputData["Duration"]); i++) {
      //   graphLabelsLocal.push(i + "");
      // }
      if (
        responseObject.Illustration.length > 0 &&
        annuiyInputData["SysWDType"] !== "None"
      ) {
        datasetGraph.push({
          label: "Guaranteed Annual Withdrawals",
          data: datasetGuaranteedWithdrawals,
          backgroundColor: "rgba(255, 208, 10)",
        });
        datasetGraph.push({
          label: "Projected Annual Withdrawals",
          data: datasetProjectedWithdrawals,
          backgroundColor: "rgba(221,216,186)",
        });
      }
      setDatasetsGraph(datasetGraph);
      // setGraphLabels(graphLabelsLocal);
      setDatagroups(datagroupsLocal);
    } else {
      setDatasetsGraph([]);
      // setGraphLabels([]);
      setDatagroups([]);
    }
  }, [annuiyInputData, responseObject]);
  return (
    <>
      <Card>
        <Card.Header className="text-center">
          {/* <h4>{annuiyInputData.Product ? annuiyInputData.Product : ""}</h4> */}
          <h4>{"Rowan, A Multi-Year Guaranteed Annuity"}</h4>
          {/* <span>$200</span> */}
        </Card.Header>
        <Card.Body className="text-center">
          <Card.Title className="mb-0">Deposit</Card.Title>
          {/* <Card.Text className="mb-0">at Age 100</Card.Text> */}
          <Card.Text className="fw-bold">
            {annuiyInputData.initamt
              ? parseInt(annuiyInputData.initamt).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              : ""}
          </Card.Text>
        </Card.Body>
        <Card.Body className="text-center">
          <Card.Title className="mb-0">Interest Rate</Card.Title>
          {/* <Card.Text className="mb-0">at Age 85</Card.Text> */}
          <Card.Text className="fw-bold">
            {responseObject.IntRate
              ? (responseObject.IntRate * 100).toFixed(2)
              : ""}{" "}
            %
          </Card.Text>
        </Card.Body>
        <Card.Body className="text-center">
          <Card.Title className="mb-0">Guaranteed Period</Card.Title>
          {/* <Card.Text className="mb-0">at Age 85</Card.Text> */}
          <Card.Text className="fw-bold">
            {annuiyInputData.Duration ? annuiyInputData.Duration : ""} Years
          </Card.Text>
        </Card.Body>
      </Card>
      {datasetsGraph.length > 0 ? (
        <Bar
          options={options}
          data={{
            labels: labels,
            datasets: datasetsGraph,
          }}
        />
      ) : null}
      <Card.Body>
        {/* <div className="header-top">PLAN OPTIONS</div> */}
        <Table bordered hover className="numericChart">
          <thead>
            <tr>
              <th>End of Year</th>
              <th>Guaranteed Surrender Value</th>
              <th>Projected Surrender Value</th>
              {annuiyInputData["SysWDType"] !== "None" ? (
                <>
                  <th>Guaranteed Annual Withdrawal</th>
                  <th>Projected Annual Withdrawal</th>
                </>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {(labels.length > 0 && datagroups.length>0)
              ? labels.map((graphLab, index) => {
                  return (
                    <tr key={index+"_"+graphLab}>
                      <td>{graphLab}</td>
                      <td>
                        {datagroups[index]["GuaranteedSurrender"]?parseInt(
                          datagroups[index]["GuaranteedSurrender"]
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        }):"-"}
                      </td>
                      <td>
                        {datagroups[index]["ProjectedSurrender"]?parseInt(
                          datagroups[index]["ProjectedSurrender"]
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        }):"-"}
                      </td>
                      {annuiyInputData["SysWDType"] !== "None" ? (
                        <>
                          <td>
                            {datagroups[index]["GuaranteedWithdrawal"]?parseInt(
                              datagroups[index]["GuaranteedWithdrawal"]
                            ).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            }):"-"}
                          </td>
                          <td>
                            {datagroups[index]["ProjectedWithdrawal"]?parseInt(
                              datagroups[index]["ProjectedWithdrawal"]
                            ).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            }):"-"}
                          </td>
                        </>
                      ) : null}
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </Card.Body>
    </>
  );
};
export default IllustrationOutput;
