import React from "react";
import { Form, Container, Alert, Row, Col } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";

const ProducerInformationForm = ({
  annuiyInputData,
  handleAnnuityInputData,
}) => {
  const handlePhone = (value) => {
    handleAnnuityInputData("ProdPhone", value);
  };
  return (
    <Container>
      <Alert key="primaryProducerInformationForm" variant="primary">
        Producer Information
      </Alert>
      <Form>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="ProducerNameFirst"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            First Name
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              placeholder="First Name"
              name="ProdFirstName"
              value={
                annuiyInputData["ProdFirstName"]
                  ? annuiyInputData["ProdFirstName"]
                  : ""
              }
              onChange={(e) =>
                handleAnnuityInputData(e.target.name, e.target.value)
              }
            />
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="ProducerLastName"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            Last Name
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              placeholder="Last Name"
              name="ProdLastName"
              value={
                annuiyInputData["ProdLastName"]
                  ? annuiyInputData["ProdLastName"]
                  : ""
              }
              onChange={(e) =>
                handleAnnuityInputData(e.target.name, e.target.value)
              }
            />
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="ProducerPhoneNumber"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            Phone Number
          </Form.Label>
          <Col sm={9}>
            <PhoneInput
              defaultCountry="US"
              country="US"
              name="ProdPhone"
              value={
                annuiyInputData["ProdPhone"] ? annuiyInputData["ProdPhone"] : ""
              }
              onChange={handlePhone}
            />
            {/* <Form.Control
              type="text"
              placeholder="Phone Number"
              name="ProdPhone"
              value={
                annuiyInputData["ProdPhone"] ? annuiyInputData["ProdPhone"] : ""
              }
              onChange={(e) =>
                handleAnnuityInputData(e.target.name, e.target.value)
              }
            /> */}
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="ProducerEmail"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            Email
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              placeholder="Email"
              name="ProdEmail"
              value={
                annuiyInputData["ProdEmail"] ? annuiyInputData["ProdEmail"] : ""
              }
              onChange={(e) =>
                handleAnnuityInputData(e.target.name, e.target.value)
              }
            />
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="ProducerAddress1"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            Address Line 1
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              placeholder="Address"
              name="ProdAdd1"
              value={
                annuiyInputData["ProdAdd1"] ? annuiyInputData["ProdAdd1"] : ""
              }
              onChange={(e) =>
                handleAnnuityInputData(e.target.name, e.target.value)
              }
            />
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="ProducerAddress2"
          style={{ marginTop: "10px" }}
        >
          <Form.Label column sm={3}>
            Address Line 2
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              placeholder="Address"
              name="ProdAdd2"
              value={
                annuiyInputData["ProdAdd2"] ? annuiyInputData["ProdAdd2"] : ""
              }
              onChange={(e) =>
                handleAnnuityInputData(e.target.name, e.target.value)
              }
            />
          </Col>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default ProducerInformationForm;
