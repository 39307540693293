import React from "react";
import { Nav, Navbar, Container, Row, Col } from "react-bootstrap";
import FooterLogo from "../assests/da-logo.svg";
import LinkedinIcon from "../assests/linkedin-icon.png";

function FooterBar() {
	return (
		<Navbar fixed="scroll" bg="light" className="footer">
			<Container className="d-flex justify-content-md-center">
				<Row>
					<Col xs="12" lg="auto">
						<div className="copyright">
						Copyright © 2021 Life Innovators.
						<span>All Rights Reserved.</span> <span>Statement of Independence</span>
						</div>
					</Col>
					<Col xs="12" lg="auto" className="credit">
						<a target="_blank" href="https://godigitalalchemy.com/">
							<img width="100%" height="100%" src={FooterLogo} alt="Made by Digital Alchemy" />
						</a>
					</Col>
				</Row>
			</Container>
		</Navbar>
	);
}

export default FooterBar;
